import React, { Component } from 'react';
import './App.css';

// import HomePage from './pages/Home';
// import OrderPage from './pages/Orders';
// import ReservationPage from './pages/Reservations';
// import SignUp from './pages/SignUp';
// import SignIn from './pages/SignIn';
// import SettingsPage from './pages/Settings';
// import MenuPage from './pages/Menu';
// import ContactUs from './pages/Contact';
// import TermsAndConds from './pages/TermsOfService';
// import PricingPage from './pages/Pricing';
// import PrivacyPolicy from './pages/PrivacyPolicy';
// import BusinessFeatures from './pages/BusinessFeatures';
// import RestaurantSearch from './pages/RestaurantsSearch';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import _ from 'lodash';
import Loadable from 'react-loadable';
import { actions as restaurantActions } from './state/actions/restaurant';
import { actions as customerUserActions } from './state/actions/customerUser';
import { actions as socketActions } from './state/actions/socket';
import 'tailwindcss/dist/base.css';
import 'semantic-ui-css/semantic.min.css';
import './css/global.css';
import './index.css';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import './websites/casual-dining/src/index.css';
import './websites/casual-dining/src/App.css';
import './websites/casual-dining/src/components/Navbar/Navbar.css';
import './websites/casual-dining/src/components/Footer/FooterOverlay.css';
import './websites/casual-dining/src/components/Menuitem/MenuItem.css';
import './websites/casual-dining/src/container/Menu/SpecialMenu.css';

// import ResetPassword from '.pages/ResetPassword';
// import ResetPasswordConfirm from './pages/ResetPasswordConfirm';
// import RestaurantHome from './pages/RestaurantHome';
// import OffersPage from './pages/Offers';
// import RequestsPage from './pages/WaiterRequests';
// import ReviewsPage from './pages/Reviews';
// import PaymentSuccess from './pages/PaymentSuccess';
// import Amenities from './pages/Amenities';
// import ProfilePage from './pages/Profile';
// import SignUpNew from './pages/SignUpNew';
import socket from './utils/socket';
// import RestaurantBooking from './pages/RestaurantBooking';
// import BookingSuccess from './pages/BookingSuccess';
// import TableCodeRedirector from './pages/TableCodeRedirector';
// import RoomPlus from './pages/RoomPlus';
import RouteChangeTracker from './utils/googleAnalytics';
import websiteDomains from './websiteDomains';
// import WhyJoinUs from './pages/WhyJoinUs';
// import WhyJoinUsRestaurants from './pages/WhyJoinUsRestaurants';
// import SignInNew from './pages/SignInNew';
// import ResetPasswordNew from './pages/ResetPasswordNew';
// import ResetPasswordConfirmNew from './pages/ResetPasswordConfirmNew';

const loading = props => {
    if (props.error) {
        window.location.reload();
        console.log(props.error);
        // return <div>Error...</div>;
        return <div />;
    }
    if (props.pastDelay) {
        return <div />;
    }
    return null;
};

const HomePage = Loadable({
    loader: () => import('./pages/Home'),
    loading,
});

const DrawTerms = Loadable({
    loader: () => import('./pages/DrawTerms'),
    loading,
});

const OrderPage = Loadable({
    loader: () => import('./pages/Orders'),
    loading,
});

const ReservationPage = Loadable({
    loader: () => import('./pages/Reservations'),
    loading,
});

const SignUp = Loadable({
    loader: () => import('./pages/SignUp'),
    loading,
});

const DelitePayPage = Loadable({
    loader: () => import('./pages/DelitePay'),
    loading,
});

const DeliteClubPage = Loadable({
    loader: () => import('./pages/DeliteClub'),
    loading,
});

const SettingsPage = Loadable({
    loader: () => import('./pages/Settings'),
    loading,
});

const MenuPage = Loadable({
    loader: () => import('./pages/Menu'),
    loading,
});

const TermsAndConds = Loadable({
    loader: () => import('./pages/TermsOfService'),
    loading,
});

const PrivacyPolicy = Loadable({
    loader: () => import('./pages/PrivacyPolicy'),
    loading,
});

const RestaurantSearch = Loadable({
    loader: () => import('./pages/RestaurantsSearch'),
    loading,
});

const RestaurantHome = Loadable({
    loader: () => import('./pages/RestaurantHome'),
    loading,
});

const OffersPage = Loadable({
    loader: () => import('./pages/Offers'),
    loading,
});

const LoyaltyPage = Loadable({
    loader: () => import('./pages/Loyalty'),
    loading,
});

const RequestsPage = Loadable({
    loader: () => import('./pages/WaiterRequests'),
    loading,
});

const ReviewsPage = Loadable({
    loader: () => import('./pages/Reviews'),
    loading,
});

const PaymentSuccess = Loadable({
    loader: () => import('./pages/PaymentSuccess'),
    loading,
});

const ProfilePage = Loadable({
    loader: () => import('./pages/Profile'),
    loading,
});

const SignUpNew = Loadable({
    loader: () => import('./pages/SignUpNew'),
    loading,
});

const RestaurantBooking = Loadable({
    loader: () => import('./pages/RestaurantBooking'),
    loading,
});

const BookingSuccess = Loadable({
    loader: () => import('./pages/BookingSuccess'),
    loading,
});

const TableCodeRedirector = Loadable({
    loader: () => import('./pages/TableCodeRedirector'),
    loading,
});

const WhyJoinUs2 = Loadable({
    loader: () => import('./pages/WhyJoinUs2'),
    loading,
});

const LoyaltyPlatformSignUp = Loadable({
    loader: () => import('./pages/LoyaltyPlatformSignUp'),
    loading,
});

const DeliteClubPromo = Loadable({
    loader: () => import('./pages/DeliteClubPromo'),
    loading,
});

const DelitePayPromo = Loadable({
    loader: () => import('./pages/DelitePayPromo'),
    loading,
});

const DeliteVirtualPromo = Loadable({
    loader: () => import('./pages/DeliteVirtualPromo'),
    loading,
});

const SignInNew = Loadable({
    loader: () => import('./pages/SignInNew'),
    loading,
});

const ResetPasswordNew = Loadable({
    loader: () => import('./pages/ResetPasswordNew'),
    loading,
});

const ResetPasswordConfirmNew = Loadable({
    loader: () => import('./pages/ResetPasswordConfirmNew'),
    loading,
});

const OurPartners = Loadable({
    loader: () => import('./pages/OurPartners'),
    loading,
});

const InsightsPage = Loadable({
    loader: () => import('./pages/Insights'),
    loading,
});

const MenuDelitePlusProfilePage = Loadable({
    loader: () => import('./pages/MenuDelitePlusProfile'),
    loading,
});

// const ReviwHubPage = Loadable({
//     loader: () => import('./pages/ReviewHub'),
//     loading,
// });

const AdminSettingsPage = Loadable({
    loader: () => import('./pages/AdminSettings'),
    loading,
});

const BlogsPage = Loadable({
    loader: () => import('./pages/Blogs'),
    loading,
});

const BlogsEditorPage = Loadable({
    loader: () => import('./pages/BlogsEditor'),
    loading,
});

const BlogPage = Loadable({
    loader: () => import('./pages/BlogPage'),
    loading,
});

const AdminReport = Loadable({
    loader: () => import('./pages/AdminReport'),
    loading,
});

const Features = Loadable({
    loader: () => import('./pages/Features'),
    loading,
});

const LoyaltyRedeemPage = Loadable({
    loader: () => import('./pages/LoyaltyRedeem'),
    loading,
});

const LoyaltyStandalonePage = Loadable({
    loader: () => import('./pages/LoyaltyStandalone'),
    loading,
});

const UserData = Loadable({
    loader: () => import('./pages/UserData'),
    loading,
});

const Campaigns = Loadable({
    loader: () => import('./pages/Campaigns'),
    loading,
});

const UnsubPage = Loadable({
    loader: () => import('./pages/Unsubscribe'),
    loading,
});

const CasualWebsiteTemplate = Loadable({
    loader: () => import('./websites/casual-dining/src/App'),
    loading,
});

const RestaurantItemAdder = Loadable({
    loader: () => import('./pages/RestaurantItemAdder'),
    loading,
});

class App extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        const { getRestaurant, connectSocket, getCustomerUser } = this.props;
        getRestaurant();
        getCustomerUser();
    }

    componentDidUpdate(prevProps) {
        const {
            restaurantFetchError,
            didLogOut,
            restaurant,
            customerUser,
            customerUserFetchError,
        } = this.props;

        if (
            restaurant &&
            restaurant.signUpStep &&
            parseInt(restaurant.signUpStep, 10) < 6
        ) {
            if (
                [
                    '/orders',
                    '/reservations',
                    '/offers',
                    '/menu',
                    '/settings',
                    '/requests',
                    '/reports',
                    '/insights',
                    '/addItems',
                    // '/reviewHub',
                    '/campaigns',
                    '/website',
                    '/users',
                ].includes(window.location.pathname)
            ) {
                window.location.replace(`/signup`);
                return;
            }
            if (window.location.pathname.includes('/loyalty/redeem/')) {
                window.location.replace('/signup');
                return;
            }
        }
        if (didLogOut && !prevProps.didLogOut) {
            window.location.replace('/signin');
            return;
        }
        if (restaurantFetchError) {
            const {
                restaurantFetchError: prevRestaurantFetchError,
            } = prevProps;
            if (!prevRestaurantFetchError) {
                const isSignIn = ['/signin', '/signup'].includes(
                    window.location.pathname
                );
                if (!isSignIn) {
                    if (
                        [
                            '/orders',
                            '/reservations',
                            '/menu',
                            '/offers',
                            '/requests',
                            '/settings',
                            '/reports',
                            '/insights',
                            // '/reviewHub',
                            '/campaigns',
                            '/addItems',
                            '/loyalty/redeem/',
                            '/delitePay',
                            '/deliteClub',
                            '/website',
                            '/users',
                        ].includes(window.location.pathname)
                    ) {
                        window.location.replace(
                            `/signin?redirect=${window.location.pathname}`
                        );
                        return;
                    }
                    if (window.location.pathname.includes('/loyalty/redeem/')) {
                        window.location.replace(
                            `/signin?redirect=${window.location.pathname}`
                        );
                        return;
                    }
                }
            }
        }
        if (customerUserFetchError) {
            const {
                customerUserFetchError: prevCustomerUserFetchError,
            } = prevProps;
            if (!prevCustomerUserFetchError && !customerUser) {
                if (['/profile'].includes(window.location.pathname)) {
                    window.location.replace('/signin');
                }
                if (
                    ['/menudelite-plus/profile'].includes(
                        window.location.pathname
                    )
                ) {
                    window.location.replace('/signin');
                }
                if (
                    window.location.pathname.includes('/restaurants/') &&
                    window.location.pathname.includes('admin')
                ) {
                    window.location.replace('/signin');
                }
                if (
                    window.location.pathname.includes('/blogs/') &&
                    window.location.pathname.includes('/create')
                ) {
                    window.location.replace('/signin');
                }
                if (
                    window.location.pathname.includes('/blogs/') &&
                    window.location.pathname.includes('/edit')
                ) {
                    window.location.replace('/signin');
                }
                if (
                    window.location.pathname.includes('reports') &&
                    window.location.pathname.includes('/admin')
                ) {
                    window.location.replace('/signin');
                }
            } else {
                if (
                    window.location.pathname.includes('/restaurants/') &&
                    window.location.pathname.includes('/admin') &&
                    !customerUser.isAdminAccount
                ) {
                    window.location.replace('/signin');
                }
                if (
                    window.location.pathname.includes('reports') &&
                    window.location.pathname.includes('/admin') &&
                    !customerUser.isAdminAccount
                ) {
                    window.location.replace('/signin');
                }
                if (
                    window.location.pathname.includes('/blogs/') &&
                    window.location.pathname.includes('/create') &&
                    !customerUser.isAdminAccount
                ) {
                    window.location.replace('/signin');
                }

                if (
                    window.location.pathname.includes('/blogs/') &&
                    window.location.pathname.includes('/edit') &&
                    !customerUser.isAdminAccount
                ) {
                    window.location.replace('/signin');
                }
            }
        }
    }

    render() {
        const {
            restaurant,
            intl,
            language,
            customerUser,
            history,
            match,
        } = this.props;

        if (websiteDomains[window.location.host]) {
            return (
                <Router>
                    <Switch>
                        <CasualWebsiteTemplate
                            restaurantId={websiteDomains[window.location.host]}
                        />
                    </Switch>
                </Router>
            );
        }

        return (
            <Router>
                <Switch>
                    <Route path="/join-us">
                        <WhyJoinUs2 intl={intl} language={language} />
                    </Route>
                    {/* <Route path="/loyalty/signUp">
                        <LoyaltyPlatformSignUp
                            intl={intl}
                            language={language}
                        />
                    </Route> */}
                    <Route path="/customers">
                        <HomePage />
                    </Route>
                    <Route
                        path="/restaurants/:restaurantId/website/edit"
                        render={props => (
                            <CasualWebsiteTemplate {...props} isEdit />
                        )}
                    />
                    <Route
                        path="/restaurants/:restaurantId/website/preview"
                        render={props => <CasualWebsiteTemplate {...props} />}
                    />
                    <Route path="/addItems/">
                        {restaurant && restaurant.id && (
                            <RestaurantItemAdder restaurant={restaurant} />
                        )}
                    </Route>
                    <Route
                        path="/eat/:tableCode/:language"
                        component={TableCodeRedirector}
                    />
                    <Route
                        path="/eat/:tableCode"
                        component={TableCodeRedirector}
                    />
                    {/* <Route path="/restaurants/search">
                        <WhyJoinUs2 intl={intl} language={language} />
                    </Route> */}
                    <Route path="/businesses">
                        <WhyJoinUs2 intl={intl} language={language} />
                    </Route>
                    <Route path="/deliteClubInfo">
                        <DeliteClubPromo intl={intl} language={language} />
                    </Route>
                    <Route path="/delitePayInfo">
                        <DelitePayPromo intl={intl} language={language} />
                    </Route>
                    <Route path="/deliteVirtual">
                        <DeliteVirtualPromo intl={intl} language={language} />
                    </Route>
                    <Route path="/pricing">
                        <Features intl={intl} language={language} />
                    </Route>
                    <Route
                        path="/restaurants/partners/view/all"
                        component={RestaurantSearch}
                    />
                    <Route
                        path="/restaurants/:restaurantId/bookingConfirmation/:reservationId"
                        component={BookingSuccess}
                    />
                    <Route
                        path="/restaurants/:restaurantId/payment/success/:token"
                        component={PaymentSuccess}
                    />
                    <Route path="/blogs/create" component={BlogsEditorPage} />
                    <Route path="/blogs/:id/edit" component={BlogsEditorPage} />
                    <Route path="/blogs/:id" component={BlogPage} />

                    <Route path="/blogs" component={BlogsPage} />
                    <Route path="/users">
                        <UserData />
                    </Route>
                    <Route
                        path="/restaurants/:restaurantId/menu"
                        component={RestaurantHome}
                    />
                    {/* <Route
                        path="/hotels/:restaurantId/amenities"
                        component={Amenities}
                    /> */}
                    <Route
                        path="/hotels/:restaurantId/menu"
                        render={props => <RestaurantHome {...props} />}
                    />
                    <Route
                        path="/partners/:restaurantId/loyalty"
                        render={props => <LoyaltyStandalonePage {...props} />}
                    />
                    <Route
                        path="/restaurants/:restaurantId/admin"
                        render={props => <AdminSettingsPage {...props} />}
                    />
                    <Route
                        path="/admin/reports"
                        render={props => <AdminReport {...props} />}
                    />
                    {/* <Route path="/pricing" component={PricingPage} /> */}
                    <Route
                        path="/restaurants/:restaurantId/book"
                        component={RestaurantBooking}
                    />
                    <Route path="/profile">
                        <ProfilePage />
                    </Route>
                    <Route path="/menudelite-plus/profile">
                        <MenuDelitePlusProfilePage />
                    </Route>
                    {/* <Route path="/signUpNew" component={SignUpNew} />
                    <Route path="/signInNew" component={SignInNew} /> */}
                    <Route path="/terms-of-service">
                        <TermsAndConds
                            headingText={intl.formatMessage({
                                id: 'terms_of_service',
                                defaultMessage: 'Terms of Service',
                            })}
                            intl={intl}
                            isSignedIn={restaurant}
                            isSignedInUser={customerUser}
                            language={language}
                        />
                    </Route>
                    <Route path="/unsubscribe">
                        <UnsubPage
                            intl={intl}
                            isSignedIn={restaurant}
                            isSignedInUser={customerUser}
                            language={language}
                        />
                    </Route>
                    <Route path="/privacy-policy">
                        <PrivacyPolicy
                            headingText={intl.formatMessage({
                                id: 'privacy_policy',
                                defaultMessage: 'Privacy & Cookie Policy',
                            })}
                            intl={intl}
                            isSignedIn={restaurant}
                            isSignedInUser={customerUser}
                            language={language}
                        />
                    </Route>
                    <Route path="/draw-terms">
                        <DrawTerms
                            headingText={intl.formatMessage({
                                id: 'draw_terms',
                                defaultMessage: 'Draw Terms',
                            })}
                            intl={intl}
                            isSignedIn={restaurant}
                            isSignedInUser={customerUser}
                            language={language}
                        />
                    </Route>
                    {/* <Route path="/contact" component={ContactUs} /> */}
                    <Route path="/signup" component={SignUpNew} />
                    <Route path="/signin" component={SignInNew} />
                    <Route
                        path="/reset-password/:hash"
                        component={ResetPasswordConfirmNew}
                    />
                    {/* <Route path="/reset-password" component={ResetPassword} /> */}
                    <Route
                        path="/reset-password"
                        component={ResetPasswordNew}
                    />
                    <Route path="/signUpBusinessEndpoint" component={SignUp} />
                    <Route path="/orders">{restaurant && <OrderPage />}</Route>
                    {/* <Route path="/roomplus">{restaurant && <RoomPlus />}</Route> */}

                    <Route path="/requests">
                        {restaurant && <RequestsPage />}
                    </Route>
                    <Route path="/offers">{restaurant && <OffersPage />}</Route>
                    {restaurant && restaurant.loyaltyEnabled && (
                        <Route
                            path="/loyalty/redeem/:passIdentifier"
                            component={LoyaltyRedeemPage}
                        />
                    )}
                    <Route path="/loyalty">
                        {restaurant && <LoyaltyPage />}
                    </Route>
                    <Route path="/delitePay">
                        {restaurant && <DelitePayPage />}
                    </Route>
                    <Route path="/deliteClub">
                        {restaurant && <DeliteClubPage />}
                    </Route>
                    <Route path="/campaigns">
                        {restaurant && <Campaigns />}
                    </Route>
                    <Route path="/reports">
                        {restaurant && <ReviewsPage />}
                    </Route>
                    <Route path="/insights">
                        {restaurant && <InsightsPage />}
                    </Route>
                    {/* <Route path="/reviewHub">
                        {restaurant && <ReviwHubPage />}
                    </Route> */}
                    <Route path="/reservations">
                        {restaurant && !restaurant.isHotel && (
                            <ReservationPage />
                        )}
                    </Route>
                    <Route path="/menu">{restaurant && <MenuPage />}</Route>
                    <Route path="/settings">
                        {restaurant && <SettingsPage />}
                    </Route>
                    <Route path="/">
                        <OurPartners intl={intl} language={language} />{' '}
                    </Route>
                </Switch>
                <RouteChangeTracker history={history} />
            </Router>
        );
    }
}

App = connect(
    (state, props) => ({
        restaurant: _.get(state, ['restaurant', 'restaurant'], null),
        customerUser: _.get(state, ['customer', 'user'], null),
        restaurantFetchError: _.get(
            state,
            ['restaurant', 'restaurantFetchError'],
            null
        ),
        customerUserFetchError: _.get(
            state,
            ['customer', 'customerFetchError'],
            null
        ),
        didLogOut: _.get(state, ['restaurant', 'didLogOut'], null),
        language: _.get(state, ['language', 'language', 'language'], null),
    }),
    { ...restaurantActions, ...socketActions, ...customerUserActions }
)(App);

export default injectIntl(App);
